<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import {getAlistContent,getValue} from './utils/api.js' 
import constant from './utils/constant';
export default {
  data() {
    return {}
  },

  computed: {
  },

  created() {
  },

  mounted() {
    getValue({id:2}).then((res)  => {
      if (res.code === 200 ){
        let settings = JSON.parse(res.result[0].value);
        localStorage.setItem("PLAYLIST_LIST",JSON.stringify(settings.PLAYLIST_LIST));
        localStorage.setItem("COLLECTS_FLODER",JSON.stringify(settings.COLLECTS_FLODER));
        localStorage.setItem("DEFAULT_WALLPAPER",JSON.stringify(settings.DEFAULT_WALLPAPER));
        localStorage.getItem("favoriteWallpaper") || localStorage.setItem("favoriteWallpaper",JSON.stringify(settings.favoriteWallpaper));
        this.$constant.playlists = settings.playlist;
        // 随机播放歌单(根据网站设置)
        this.$nextTick(() => {
          this.$store.commit(Math.random()<0.5?"changePlaylist":"pushPlaylist", this.$constant.playlists[Math.floor(Math.random() * this.$constant.playlists.length)]);
        })
        if(!localStorage.getItem("emoji")){
          this.EMOJI = [...this.EMOJI,...settings.emoji]
          localStorage.setItem("emoji", JSON.stringify(this.EMOJI));
        } 
        this.$store.state.fonts = settings.fonts;
        this.loadFont(localStorage.getItem("fontFamilyName") || settings.fonts[2].name);
      }
    })
    // localStorage.getItem("emoji") || getValue({type:"表情包"}).then((res)  => {
    //   if (res.code === 200 ){
    //     let parsedArray = res.result.map(obj => JSON.parse(obj.value));
    //     parsedArray.forEach(element => {
    //       this.EMOJI = [...this.EMOJI,...element]
    //       localStorage.setItem("emoji", JSON.stringify(this.EMOJI));
    //     });
    //   }
    // })
    getAlistContent({"path": "/wallpaper","password": "",
	"page": 1,"per_page": 20,"refresh": false}).then((res) => {
      window.localStorage.setItem("alistSource_wallpaperFolder", JSON.stringify(res.data.content));
    })
    .catch((error) => {
      console.log(error);
    });
  },

  methods: {
    loadFont(fontFamily) {
      fontFamily = this.$store.state.fonts.find(font => font.name === fontFamily);
      const fontUrl = fontFamily?.href;
      const fontLink = document.createElement('link');
      fontLink.setAttribute('rel', 'stylesheet');
      fontLink.setAttribute('href', fontUrl);
      document.head.appendChild(fontLink);
      document.documentElement.style.setProperty('--globalFont', fontFamily?.import);
    }
  }
}
</script>

<style scoped>

</style>
