import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import http from './utils/request'
import common from './utils/common'
import constant from './utils/constant'
import mavonEditor from 'mavon-editor'
import VueHead from 'vue-head';
Vue.use(VueHead);

//引入json配置
import EMOJI_DEFAULT from './assets/json/emoji.json' 
//引入js
// import './utils/live2d'
import './utils/title'
//引入css
import './assets/css/animation.css'
import './assets/css/index.css'
import './assets/css/tocbot.css'
import './assets/css/color.css'
import './assets/css/markdown-highlight.css'
import './assets/css/font-awesome.min.css'
import 'mavon-editor/dist/css/index.css'
//点击涟漪效果
import Ripple from 'vue-ripple-directive'
import {vueBaberrage} from 'vue-baberrage'
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload, {
  lazyComponent: true,
  loading: 'https://mybox-1257251314.cos.ap-chengdu.myqcloud.com/load.gif'
})
Ripple.color = 'var(--rippleColor)'
Vue.directive("ripple", Ripple)
Vue.use(ElementUI)
Vue.use(vueBaberrage)
Vue.use(mavonEditor)
Vue.use(store)
Vue.prototype.EMOJI = EMOJI_DEFAULT

Vue.prototype.$http = http
Vue.prototype.$common = common
Vue.prototype.$constant = constant
Vue.config.productionTip = false

Vue.prototype.global = {
  showVideoPoster:false,
}
top.faceReg = common.faceReg;

// import 'viewerjs/dist/viewer.css'
// import VueViewer from 'v-viewer'
// Vue.use(VueViewer)

const app = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// Vue.prototype.ipData = {}
// fetch("/getIp",{
//   mode: 'cors',
// }).then((e=>e.json())).then((e=>{
//   let a = JSON.stringify(e);
//   localStorage.setItem("ipData", a);
//   Vue.prototype.ipData = e;
//   Vue.prototype.$message({
//     type: 'success',
//     message: `欢迎IP:${e.query??'0.0.0.0'},来自${e.country??'中国'}${e.regionName??''}${e.city??''}的朋友！`
//   });检机构都是差不多都是有这个
// }))

console.log('%c hello world! \n Print by Roginshin. ', 
'background: #ed556a;line-height:50px;font-size:24px; color: #f6cec1;font-family:var(--globalFont)');